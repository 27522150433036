type LabelProps = {
    labelFor: string
    labelText: string
}

const Label = (props:LabelProps) => {
    return (
        <label className="block tracking-wide text-white text-base sm:text-lg font-bold mb-2" htmlFor={props.labelFor}>
            {props.labelText}
        </label>
    )
}

export default Label;