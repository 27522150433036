import { httpsCallable } from "firebase/functions";
import { functions} from "./Firebase";

export const generateTicket = (

  formOptions: string[],
  formData: {
    firstName: string, 
    lastName: string, 
    emailAddress: string, 
    description: string,
    product: {id:string, name: string},
    issue: {id:string, name: string},
    subissue?: {id:string, name: string},
    appVersion?: string,
    deviceType?: string,
    operatingSystem?: string,
    firmware?: string,
    serialNumber?: string,
    phone?: string,
    role?: string,
    errorCode?: string
  },
  token: string

  ) => {

    const addTicket = httpsCallable(functions, 'addSupportTicket');

    type TicketData = {
      [key: string]: string | Date | {id:string, name: string} | undefined
    }

    let ticketData:TicketData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      emailAddress: formData.emailAddress,
      phone: formData.phone,
      role: formData.role,
      date: new Date(),
      description: formData.description,
      product: formData.product,
      issue: formData.issue,
      subissue: formData.subissue
    }

    if (formOptions.includes('hand')){
      ticketData = {
        ...ticketData,
        firmware: formData.firmware,
        serialNumber: formData.serialNumber,
        errorCode: formData.errorCode
      }
    }

  if (formOptions.includes('app')){
    ticketData = {
      ...ticketData,
      appVersion: formData.appVersion,
      deviceType: formData.deviceType,
      operatingSystem: formData.operatingSystem
    }
  }

  return new Promise ((resolve, reject)=>{
    addTicket({ ticketData: ticketData, token: token })
    .then((response) => {
      resolve(response.data)
    })
    .catch(() => {
      resolve(false)
    })
  })
}
