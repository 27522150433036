import { languageSelector as ls } from "../../../languages/languageSelector";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const ErrorPage = () => {
    const linkCheck = (markdown:string) => {
        return(
        <ReactMarkdown 
            children={markdown} 
            remarkPlugins={[remarkGfm]}
            components={{
                a: ({node, ...props}) => 
                    <a className={'text-blue-400 underline'} {...props} />}}
        />)
    }
    return(
        <div className={'h-full flex flex-col justify-between'}>
            <h1 className={'text-white font-bold text-2xl sm:text-4xl mt-4 mb-1 sm:mt-12 sm:mb-6'}> 
                {ls.getText("error_page_title")}
            </h1>
            <div className='mx-5 mt-2 mb-6 text-sm sm:text-2xl text-white'>
                <p className='my-4'>{linkCheck(ls.getText('error_page_one'))}</p>
                <p className='my-4'>{linkCheck(ls.getText('error_page_two'))}</p>
            </div>
            <div className="hidden sm:flex">
                <img 
                    className={``} 
                    alt={'Desktop 404'} 
                    src={require(`./../../../assets/images/404-Desktop.png`)} 
                />
            </div>
            <div className="flex sm:hidden">
                <img 
                    className={``} 
                    alt={'Mobile 404'} 
                    src={require(`./../../../assets/images/404-Mobile.png`)} 
                />
            </div>
        </div>
    )
}

export default ErrorPage;