import { initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const config = {
    apiKey: "AIzaSyBxLZMwE6CwuscuPpF3_vySoIYYypCptdg",
    authDomain: "covvi-go-app.firebaseapp.com",
    databaseURL: "https://covvi-go-app.firebaseio.com",
    projectId: "covvi-go-app",
    storageBucket: "covvi-go-app.appspot.com",
    messagingSenderId: "212116284044",
    appId: "1:212116284044:web:e96099ba028034d11cd1a9"
};

const app = initializeApp(config);
export const firestore = getFirestore(app);
export const functions = getFunctions(app);

if (window.location.hostname === "localhost") {
    connectFirestoreEmulator(firestore, "localhost", 8082)
    connectFunctionsEmulator(functions, "localhost", 5001)
}