import { useNavigate, createSearchParams } from 'react-router-dom'
import { useTicketContext } from '../../context/TicketContext'
import structure from '../../util/structure.json'
import Product from '../ui/product/Product'
import { languageSelector as ls } from '../../languages/languageSelector'

const Products = () => {
    const { setProduct, setFormOptions } = useTicketContext()
    const navigate = useNavigate()
    const selectProduct = (id: string) => {
        const currentProduct = structure.filter((col,i)=>{
            return col.id===id
        })[0]
        setProduct({id: id, name: currentProduct.name})
        setFormOptions(currentProduct.form_options)
        navigate({
            pathname: "issues",
            search: createSearchParams({
                p: id
            }).toString()
        })
    }

    return (
            <div className={'max-w-6xl mx-auto'}>
                <h1 className={'text-white font-bold text-2xl sm:text-4xl mt-4 mb-1 sm:mt-12 sm:mb-6'}> 
                    {ls.getText('product')}
                </h1>
                <div className='mx-5 mt-2 mb-6 text-sm sm:text-base'>
                    <p className='my-2 text-white'>{ls.getText('welcome_text')}</p>
                    <p className='my-2 text-white'>{ls.getText('welcome_text_two')}</p>
                </div>
                <div className={'flex flex-wrap px-5 justify-between'}>

                {structure.map((top, i)=>{
                        return <Product key={i} id={top.id} name={top.name} onClick={() => selectProduct(top.id)}/>
                    })
                }
                </div>
            </div>
        )
}

export default Products;