import { createContext, useContext } from "react"
import { TicketInterface, TicketObject } from "../types/TicketTypes"


export interface TicketContextInterface {
    product: TicketObject
    issue: TicketObject
    subissue: TicketObject
    formOptions: string[]
    type: string
    children: TicketInterface[] | string[]
    setProduct:(TicketObject:TicketObject) => void
    setIssue:(TicketObject: TicketObject) => void
    setSubissue:(TicketObject:TicketObject) => void
    setFormOptions:(formOptions: string[]) => void
    setType:(type: string) => void
    setChildren:(children:TicketInterface[] | string[]) => void
}

export const TicketContext = createContext<TicketContextInterface>({
    product: {id:'', name:''},
    issue: {id:'', name:''},
    subissue: {id:'', name:''},
    formOptions: [],
    type: '',
    children: [],
    setProduct: () => {},
    setIssue: () => {},
    setSubissue: ()=> {},
    setFormOptions: () => {},
    setType: () => {},
    setChildren: () => {}
})

export const useTicketContext = () => useContext(TicketContext)
