import React from 'react';
import Header from '../header/Header';

type LayoutProps = {
    children: React.ReactNode
}

const Layout = (props: LayoutProps) => {

    return(
        <div className="flex flex-col h-screen justify-between">
            <Header />
            <div className={'flex-1'}>
                {props.children}
            </div>
        </div>
    )
}

export default Layout;