import { useNavigate, createSearchParams } from "react-router-dom";
import { useTicketContext } from "../../context/TicketContext";
import { languageSelector as ls } from "../../languages/languageSelector";
import Button from "../ui/button/Button";
import OptionRow from "../ui/option row/OptionRow";
import Option from "../ui/option/Option";

const CommonSolutions = () => {
    const { product, issue, subissue, children } = useTicketContext()
    const navigate = useNavigate()

    return(
        <Option type={'common_solutions'} product={product} onClick={()=>navigate('/')}>
            <div className={'max-w-3xl m-auto text-left text-white'}>
                <div className={'text-sm sm:text-xl py-2 sm:pb-2'}>
                {ls.getText('common_solutions_top')}
                </div>
                <ul className={'list-disc mx-5 my-3 sm:m-5 list-outside'}>
                    {children.map((child, i)=>{
                        if(typeof child === 'string'){
                            return <OptionRow key={i} name={child}/>
                        }else{
                            return false
                        }         
                    })}
                </ul>
                <div className={'text-sm sm:text-xl py-2 sm:pt-2'}>
                    {ls.getText('common_solutions_bottom')}
                </div>
                <div className={'flex justify-center mt-6'}>
                    <Button 
                        name={ls.getText('get_in_touch')} 
                        width={'w-2/3 sm:w-1/3'} 
                        onClick={() => {
                            if(subissue && subissue.id!==""){
                                navigate({
                                    pathname: "/ticket",
                                    search: createSearchParams({p: product.id, i: issue.id, s: subissue.id}).toString()
                                })
                            }else{
                                navigate({
                                    pathname: "/ticket",
                                    search: createSearchParams({p: product.id, i: issue.id}).toString()
                                })
                            }
                            
                        }}/>
                </div>
            </div>
        </Option>
        
    )
}

export default CommonSolutions;