import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

type OptionRowProps = {
    name: string 
    pointer?: boolean
    onClick?: () => void
    }

const linkCheck = (markdown:string) => {
    return(
    <ReactMarkdown 
        children={markdown} 
        remarkPlugins={[remarkGfm]}
        components={{
            a: ({node, ...props}) => 
                <a className={'text-blue-400 underline'} {...props} />}}
    />)
}

const OptionRow = (props: OptionRowProps) => {
    return <li className={`text-white text-sm sm:text-xl ${props.pointer && 'cursor-pointer hover:text-copper'} `} onClick={props.onClick}>{linkCheck(props.name)}</li>
}

export default OptionRow