import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Products from './components/products/Products';
import Form from './components/form/Form';
import Issues from './components/issues/Issues';
import Subissues from './components/subissues/Subissues';
import CommonSolutions from './components/commonsolutions/CommonSolutions';
import ErrorPage from './components/ui/error page/ErrorPage';

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Products/>} />
        <Route path="issues" element={<Issues/>}>
          <Route path=":productId" element={<Issues/>}/>
        </Route>
        <Route path="subissues" element={<Subissues />}>
          <Route path=":issueId" element={<Subissues/>}/>
        </Route>
        <Route path="common_solutions" element={<CommonSolutions/>}>
          <Route path=":subissueId" element={<CommonSolutions/>}/>
        </Route>
        <Route path="ticket" element={<Form />}>
          <Route path=":ticketId" element={<Form />} />
        </Route>
        <Route path="error" element={<ErrorPage/>} />
        <Route path="*" element={<ErrorPage/>} />
      </Route>
    </Routes>
  </BrowserRouter>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
