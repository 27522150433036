import { useEffect, useState } from "react"
import { isPossiblePhoneNumber } from "libphonenumber-js"
import { generateTicket } from "../../util/firebase/FirebaseHelper"
import { useTicketContext } from "../../context/TicketContext"
import { languageSelector as ls } from "../../languages/languageSelector"

import Button from "../ui/button/Button"
import Modal from "../ui/modal/Modal"
import Radio from "../ui/Inputs/radio/Radio"
import Input from "../ui/Inputs/input/Input"
import Selection from "../ui/Inputs/selection/Selection"
import TextArea from "../ui/Inputs/text area/TextArea"
import {
  FormInputState,
  FormRadioState,
  FormSelectionState,
  FormTextAreaState,
} from "../../types/FormOptionTypes"

const SITE_KEY = "6LdxjysfAAAAAHSDrnx_Pr9O-KNKtfZxREQWSK6m"
declare global {
  interface Window {
    grecaptcha: any
  }
}

type SendStatus = "pending" | "success" | "failure"

const Form = () => {
  const { product, issue, subissue, formOptions } = useTicketContext()

  const [showModal, setShowModal] = useState<boolean>(false)
  const [sendStatus, setSendStatus] = useState<SendStatus>("pending")
  const [ticketId, setTicketId] = useState<string>("")
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true)
  const [firstName, setFirstName] = useState<FormInputState>({
    name: ls.getText("first_name"),
    labelText: ls.getText("name"),
    type: "text",
    placeholder: `${ls.getText("first_name")} *`,
    errorMessage: ls.getText("min_two"),
    error: false,
    value: "",
    validation: {
      mandatory: true,
      hasChanged: false,
      minLength: 2,
    },
  })
  const [lastName, setLastName] = useState<FormInputState>({
    name: ls.getText("last_name"),
    type: "text",
    placeholder: `${ls.getText("last_name")} *`,
    errorMessage: ls.getText("min_two"),
    error: false,
    value: "",
    validation: {
      mandatory: true,
      hasChanged: false,
      minLength: 2,
    },
  })
  const [email, setEmail] = useState<FormInputState>({
    name: ls.getText("email"),
    labelText: ls.getText("email"),
    type: "email",
    placeholder: `${ls.getText("email")} *`,
    errorMessage: ls.getText("valid_email"),
    error: false,
    value: "",
    validation: {
      mandatory: true,
      hasChanged: false,
    },
  })
  const [phone, setPhone] = useState<FormInputState>({
    name: ls.getText("phone"),
    labelText: ls.getText("phone"),
    type: "tel",
    placeholder: ls.getText("phone"),
    errorMessage: ls.getText("valid_phone"),
    error: false,
    value: "",
    validation: {
      mandatory: false,
      hasChanged: false,
    },
  })
  const [role, setRole] = useState<FormSelectionState>({
    name: ls.getText("role"),
    labelText: ls.getText("role"),
    options: [
      ls.getText("user"),
      ls.getText("clinician"),
      ls.getText("distributor"),
      ls.getText("dont_know"),
    ],
    value: ls.getText("dont_know"),
  })

  const [appVersion, setAppVersion] = useState<FormSelectionState>({
    name: ls.getText("app_version"),
    labelText: ls.getText("app_version"),
    options: [
      "1.2.1",
      "1.2.2",
      "1.2.3",
      "1.2.4",
      "2.0.0",
      "2.1.0",
      "2.1.1",
      ls.getText("dont_know"),
    ],
    value: ls.getText("dont_know"),
  })
  const [device, setDevice] = useState<FormRadioState>({
    name: ls.getText("device"),
    labelText: ls.getText("device"),
    radioChoices: [ls.getText("mobile"), ls.getText("tablet")],
    value: ls.getText("mobile"),
  })
  const [os, setOs] = useState<FormRadioState>({
    name: ls.getText("os"),
    labelText: ls.getText("os"),
    radioChoices: [ls.getText("ios"), ls.getText("android")],
    value: ls.getText("ios"),
  })
  const [firmware, setFirmware] = useState<FormSelectionState>({
    name: ls.getText("firmware"),
    labelText: ls.getText("firmware"),
    options: ["2.10.01", "3.6.29", "3.9.14", "4.5.4", "5.1.25", "5.9.21", ls.getText("dont_know")],
    value: ls.getText("dont_know"),
  })
  const [serial, setSerial] = useState<FormInputState>({
    name: ls.getText("serial_no"),
    labelText: ls.getText("serial_no"),
    labelInfoLoc: "serial_number_description",
    type: "text",
    placeholder: ls.getText("serial_no"),
    errorMessage: ls.getText("serial_error"),
    value: "",
    error: false,
    validation: {
      mandatory: false,
      hasChanged: false,
      minLength: 16,
      maxLength: 16,
      startSubString: ["DH1", "CV1", "LN1"],
    },
  })
  const [errorCode, setErrorCode] = useState<FormInputState>({
    name: ls.getText("error_code"),
    labelText: ls.getText("error_code"),
    labelInfoLoc: "error_code_description",
    type: "text",
    placeholder: ls.getText("error_code"),
    errorMessage: ls.getText("error_code_error"),
    error: false,
    value: "",
    validation: {
      mandatory: false,
      hasChanged: false,
    },
  })

  const [additionalInfo, setAdditionalInfo] = useState<FormTextAreaState>({
    name: ls.getText("describe_issue"),
    labelText: ls.getText("additional_info"),
    placeholder: ls.getText("description_ph"),
    value: "",
  })

  const checkError = (value: string, state: FormInputState) => {
    const validation = state.validation
    const type = state.type
    const reg =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let trimmmedVal = value.trim()
    if (state === serial) {
      trimmmedVal = trimmmedVal.toUpperCase()
    }

    if (type === "tel" && !trimmmedVal.includes("+")) {
      trimmmedVal = "+" + trimmmedVal
    }
    if (trimmmedVal === "+") trimmmedVal = ""

    let checkError
    let emailError = ls.getText("valid_email")
    if (validation.minLength && trimmmedVal.length < validation.minLength) {
      checkError = true
    } else if (validation.maxLength && trimmmedVal.length > validation.maxLength) {
      checkError = true
    } else if (type === "email" && !reg.test(trimmmedVal)) {
      checkError = true
    } else if (
      type === "email" &&
      trimmmedVal.substring(trimmmedVal.length - 10, trimmmedVal.length) === "@covvi.com"
    ) {
      emailError = ls.getText("covvi_email_error")
      checkError = true
    } else if (type === "tel" && !isPossiblePhoneNumber(trimmmedVal)) {
      checkError = true
    } else if (
      validation.startSubString &&
      !validation.startSubString.includes(trimmmedVal.substring(0, 3))
    ) {
      checkError = true
    } else {
      checkError = false
    }

    if (!validation.mandatory && trimmmedVal === "") checkError = false

    switch (state) {
      case firstName:
        return setFirstName({
          ...state,
          value: trimmmedVal,
          error: checkError,
          validation: { ...validation, hasChanged: true },
        })
      case lastName:
        return setLastName({
          ...state,
          value: trimmmedVal,
          error: checkError,
          validation: { ...validation, hasChanged: true },
        })
      case email:
        return setEmail({
          ...state,
          value: trimmmedVal,
          error: checkError,
          errorMessage: emailError,
          validation: { ...validation, hasChanged: true },
        })
      case serial:
        return setSerial({ ...state, value: trimmmedVal, error: checkError })
      case phone:
        return setPhone({ ...state, value: trimmmedVal, error: checkError })
      case errorCode:
        return setErrorCode({ ...state, value: trimmmedVal, error: checkError })
      default:
        break
    }
  }

  const submitForm = () => {
    const ticketData = {
      firstName: firstName.value,
      lastName: lastName.value,
      emailAddress: email.value,
      phone: phone.value,
      role: role.value,
      description: additionalInfo.value,
      product: { id: product.id, name: product.name },
      issue: { id: issue.id, name: issue.name },
      subissue: { id: subissue.id, name: subissue.name },
      appVersion: appVersion.value,
      deviceType: device.value,
      operatingSystem: os.value,
      firmware: firmware.value,
      serialNumber: serial.value,
      errorCode: errorCode.value,
    }

    if (
      !(
        firstName.validation.hasChanged ||
        lastName.validation.hasChanged ||
        email.validation.hasChanged
      )
    ) {
      checkError(firstName.value, firstName)
      checkError(lastName.value, lastName)
      checkError(email.value, email)
    } else {
      if (!(firstName.error || lastName.error || email.error)) {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(SITE_KEY, { action: "submit" }).then((token: string) => {
            setSendStatus("pending")
            setShowModal(true)
            generateTicket(formOptions, ticketData, token).then((response: any) => {
              if (response) {
                setTicketId(response.id)
                setSendStatus("success")
              } else {
                setSendStatus("failure")
              }
            })
          })
        })
      }
    }
  }

  const setModal = () => {
    switch (sendStatus) {
      case "pending":
        return (
          <Modal
            showModal={showModal}
            title={ls.getText("submitting")}
            content={
              <div className="flex justify-center items-center">
                <svg
                  role="status"
                  className="w-16 h-16 mt-5 text-gray-200 animate-spin dark:text-gray-600 fill-copper"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </div>
            }
          />
        )
      case "success":
        return (
          <Modal
            showModal={showModal}
            title={ls.getText("ticket_submitted_title")}
            content={
              <div className="my-2 text-center font-lg">
                <p className="text-base text-white my-4">
                  {" "}
                  {ls.getText("ticket_submitted_msg_one")}
                  {ticketId}
                </p>
                <p className="text-base text-white my-4">
                  {" "}
                  {ls.getText("ticket_submitted_msg_two")}
                </p>
              </div>
            }
            buttonClose={{
              title: ls.getText("okay"),
              onClick: () => {
                window.location.href = "https://covvi.com"
              },
            }}
          />
        )
      case "failure":
        return (
          <Modal
            showModal={showModal}
            title={ls.getText("ticket_fail")}
            content={
              <div className="my-2 text-center">
                {[
                  ls.getText("ticket_fail_msg_one"),
                  ls.getText("ticket_fail_msg_two"),
                  ls.getText("ticket_fail_msg_three"),
                ].map((p, i) => {
                  return (
                    <p key={i} className="text-base text-white font-medium my-4">
                      {p}
                    </p>
                  )
                })}
              </div>
            }
            buttonClose={{
              title: ls.getText("okay"),
              onClick: () => {
                setShowModal(false)
              },
            }}
          />
        )
      default:
        return <Modal showModal={true} title={""} content={<></>} />
    }
  }

  useEffect(() => {
    const loadScriptByURL = (id: string, url: string, callback: () => void) => {
      const isScriptExist = document.getElementById(id)
      if (!isScriptExist) {
        var script = document.createElement("script")
        script.type = "text/javascript"
        script.src = url
        script.id = id
        script.onload = () => {
          if (callback) callback()
        }
        document.body.appendChild(script)
      }
      if (isScriptExist) callback()
    }
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      () => {}
    )
  }, [])

  useEffect(() => {
    if (!(firstName.error || lastName.error || email.error)) {
      if (
        firstName.validation.hasChanged &&
        lastName.validation.hasChanged &&
        email.validation.hasChanged
      ) {
        setSubmitDisabled(false)
      }
    } else {
      setSubmitDisabled(true)
    }
  }, [firstName, lastName, email])

  return (
    <>
      <div className="space-y-5 m-5">
        <div className={"max-w-4xl m-auto text-md sm:text-xl text-white sm:px-3 my-0 sm:mt-12"}>
          <h1 className={"py-3 sm:mb-5 text-center text-xl sm:text-4xl text-white font-bold"}>
            {ls.getText("get_in_touch_caps")}
          </h1>
          <p className="text-base">{ls.getText("form_top")}</p>
        </div>
        <form className="w-full max-w-4xl m-auto text-left sm:py-3">
          <div className="flex flex-wrap items-end">
            <Input state={firstName} onChange={(v: string) => checkError(v, firstName)} />
            <Input state={lastName} onChange={(v: string) => checkError(v, lastName)} />
            <Input state={email} onChange={(v: string) => checkError(v, email)} />
          </div>
          <div className="flex flex-wrap">
            <Input state={phone} onChange={(v: string) => checkError(v, phone)} />
            <Selection state={role} onChange={(v: string) => setRole({ ...role, value: v })} />
          </div>

          {formOptions.includes("app") && (
            <div className="flex flex-wrap sm:py-3">
              <Selection
                state={appVersion}
                onChange={(v: string) => setAppVersion({ ...appVersion, value: v })}
              />
              <Radio state={device} onChange={(v: string) => setDevice({ ...device, value: v })} />
              <Radio state={os} onChange={(v: string) => setOs({ ...os, value: v })} />
            </div>
          )}

          {formOptions.includes("hand") && (
            <div className="flex flex-wrap sm:py-3">
              <Selection
                state={firmware}
                onChange={(v: string) => setFirmware({ ...firmware, value: v })}
              />
              <Input state={errorCode} onChange={(v: string) => checkError(v, errorCode)} />
              <Input state={serial} onChange={(v: string) => checkError(v, serial)} />
            </div>
          )}

          <div className="flex flex-wrap sm:py-3">
            <TextArea
              state={additionalInfo}
              onChange={(v: string) => setAdditionalInfo({ ...additionalInfo, value: v })}
            />
          </div>
        </form>
        <div className={"flex justify-center pb-5"}>
          <Button
            name={"Submit"}
            disabled={submitDisabled}
            width={"w-1/2 sm:w-1/4"}
            onClick={() => {
              submitForm()
            }}
          />
        </div>
      </div>
      {setModal()}
    </>
  )
}

export default Form
