import { useState } from "react"
import { languageSelector as ls} from "../../../../languages/languageSelector"
import Modal from "../../modal/Modal"

type InputsProps = {
    state:{
        name:string
        labelText?: string
        labelInfoLoc?: string
        type:string
        placeholder: string
        error: boolean
        errorMessage: string
        value: string
        validation:{
            mandatory: boolean
        }
    }  
    onChange: (val: string) => void
}

const Input = (props:InputsProps) => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const {name, labelText, labelInfoLoc, type, placeholder, errorMessage, value, error, validation} = props.state

    let errorStyle = 'text-transparent'
    let errorBorder
    if(error){
        if (!validation.mandatory){
            errorStyle = 'text-white'
        }else{
            errorStyle = 'text-red-500'
            errorBorder = 'border-red-600'
        }

    }
     
    return (
            <div className="w-full md:w-1/3 sm:px-3 mb-2 flex-row">

                {labelText && 
                <label className="block tracking-wide text-white text-base sm:text-lg font-bold mb-2" htmlFor={name}>
                    <p className="inline" >{labelText}</p> 
                        {labelInfoLoc && 
                        <div className="inline pl-2 cursor-pointer" onClick={() => setShowModal(true)}>
                            <svg className="inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
                                <path className="fill-white" d="M 10 1.667969 C 5.40625 1.667969 1.667969 5.40625 1.667969 10 C 1.667969 14.59375 5.40625 18.332031 10 18.332031 C 14.59375 18.332031 18.332031 14.59375 18.332031 10 C 18.332031 5.40625 14.59375 1.667969 10 1.667969 Z M 10 3.332031 C 13.691406 3.332031 16.667969 6.308594 16.667969 10 C 16.667969 13.691406 13.691406 16.667969 10 16.667969 C 6.308594 16.667969 3.332031 13.691406 3.332031 10 C 3.332031 6.308594 6.308594 3.332031 10 3.332031 Z M 9.167969 5.832031 L 9.167969 7.5 L 10.832031 7.5 L 10.832031 5.832031 Z M 9.167969 9.167969 L 9.167969 14.167969 L 10.832031 14.167969 L 10.832031 9.167969 Z M 9.167969 9.167969 "/>
                            </svg>
                        </div>
                    }
                </label>
                }

                <input 
                    className={`appearance-none block w-full bg-backGround text-white border-2 py-3 px-4 leading-tight ${errorBorder} focus:ring-0 focus:border-2 focus:outline-none focus:border-copper focus:bg-backGround focus:text-white`}
                    id={name} 
                    type={type} 
                    placeholder={placeholder}
                    onChange={(e)=>{
                        const {value} = e.target
                        props.onChange(value)
                    }}
                    value={value}
                />
                <p className={`${errorStyle} text-xs italic pt-1`}>{errorMessage}</p>
            
                {labelInfoLoc && 
                    <Modal 
                        showModal={showModal}
                        title={name}
                        content={
                           <div>
                                <img 
                                    className={'group transition ease-in-out delay-150 group-hover:scale-105 duration-300 py-4'} 
                                    alt={name} 
                                    src={require(`./../../../../assets/images/demonstrations/${labelInfoLoc}.jpeg`)} 
                                />
                                <p className="text-white">
                                    {ls.getText(labelInfoLoc)}
                                </p>
                           </div>
                            }
                        buttonClose={{
                            title:  ls.getText('close'),
                            onClick: () => {setShowModal(false)}
                            }} 
                    />}
            </div>
    )
}

export default Input;