import React from 'react'

type CatagoryProps = {
    name: string 
    id: string
    onClick?: () => void
    }

const Catagory = (props:CatagoryProps) => {
    const clickHandler = () =>{
        if (props.onClick) props.onClick()
    }

    let imgSrc
    try{
        require(`./../../../assets/images/catagories/${props.id}.png`)
        imgSrc = props.id
    } catch (err) {
        imgSrc = 'other_catagory'
    }

    return (
        <div className={"inline-flex text-white cursor-pointer border-2 border-slate rounded-lg p-2 items-center transition ease-in-out delay-50  hover:bg-copper hover:border-copperHover duration-300 "} onClick={()=>{clickHandler()}}>
                <img 
                    className={'object-contain h-12 flex-none'} 
                    alt={props.name} 
                    src={require(`./../../../assets/images/catagories/${imgSrc}.png`)} 
                />
            <p className={'flex-1 text-left pl-4'}>
                {props.name}
            </p>
        </div>
    )

}

export default Catagory