import { languageSelector as ls} from "../../../languages/languageSelector"
import Button from "../button/Button"
import Product from "../product/Product"

type OptionProps = {
    type: string,
    product: {
        id: string,
        name: string
    },
    onClick: () => void,
    children: React.ReactNode
}


const Option = (props:OptionProps) => {
    return (
        <>
            <div className={'sm:h-full flex flex-col'}>
                <div className={'sm:py-5 '}>
                    <h1 className={`${props.type === 'subissue' && 'hidden sm:block'} text-white font-bold text-2xl sm:text-4xl pt-10 flex-none hidden sm:block`}> 
                        {props.type === 'issues' || props.type === 'subissues' ? ls.getText('issue') : ls.getText('common_solutions')}
                    </h1>

                    <div className={'flex-1 flex flex-col sm:h-full sm:flex-row items-center'}>

                        <div className={'flex-1 sm:border-r-2 h-100 border-slate pt-5'}>
                            <div className={'flex items-center justify-center flex-col'}>
                                <Product id={props.product.id} name={props.product.name} width={'w-1/2 sm:w-2/3 '}/>
                                <div className={'w-full sm:flex justify-center '}>
                                    <Button name={ls.getText('change_product')} transparent={true}  width={'w-2/3'} onClick={props.onClick}/>
                                </div>
                            </div>
                        </div>

                        <div className={`flex-1 px-10 w-full py-5`}>
                            <h1 className={`${props.type === 'subissue' && 'hidden'} text-white font-bold text-2xl sm:text-4xl my-3 flex-none sm:hidden block`}> 
                                {props.type === 'issue' || props.type === 'subissue' ? ls.getText('issue') : ls.getText('common_solutions')}
                            </h1>
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Option;