import Label from "../Label"

interface RadioProps {
    state:{
        name: string
        labelText: string
        radioChoices: string[]
        errorMessage?: string
        value: string
    }
    onChange: (e: string) => void
}

const Radio = (props: RadioProps) => {
    
    return (
        <div className="w-full md:w-1/3 sm:px-5 mb-4 sm:mb-0">
            <Label labelFor={props.state.name} labelText={props.state.labelText}/>
            <div className="flex justify-start pl-1 sm:pl-0">
                {props.state.radioChoices.map((choice, i)=>{
                    return(
                        <div key={i} className="form-check form-check-inline pr-8 sm:pr-3 mb-2 pt-0 sm:pt-3">
                            <input className="form-check-input form-check-input rounded-full h-4 w-4 outline-none border-0 bg-white text-copper checked:bg-copper focus:ring-0 focus:outline-none transition duration-200 mt-1 align-top float-left mr-2 cursor-pointer" 
                                type="radio" 
                                name={`radio-${choice.replace(' ','_')}-${i}`} 
                                id={`radio-${choice.replace(' ','_')}-${i}`} 
                                value={choice}
                                checked={choice===props.state.value}
                                onChange={()=>{props.onChange(choice)}}
                                />
                            <label className="form-check-label inline-block text-white" htmlFor={`radio-${choice.replace(' ','_')}-${i}`}>{choice}</label>
                        </div>
                        )
                })}
            </div>
        </div>
        
    )
}

export default Radio;