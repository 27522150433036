import Label from "../Label"

type TextAreaProps = {
    state:{
        name:string
        labelText?: string
        placeholder: string
        value: string
    }
    onChange: (e: string) => void
}

const TextArea = (props: TextAreaProps) => {
    return (
        <div className="w-full sm:px-3">
            {props.state.labelText && <Label labelFor={`textArea_${props.state.name}`} labelText={props.state.name} />}
            <textarea 
            className={"appearance-none block w-full text-base bg-backGround text-white border-2 borderborder-gray-200 py-3 px-4 mb-3 leading-tight focus:ring-0 focus:border-2 focus:outline-none focus:border-copper focus:bg-backGround focus:text-white"}
            id={`textArea_${props.state.name}`} 
            rows={5} 
            placeholder={props.state.placeholder} 
            onChange={(e)=>{
                const {value} = e.target
                props.onChange(value)
            }}></textarea>
        </div>
    )
}

export default TextArea;