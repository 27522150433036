import { useNavigate, createSearchParams } from "react-router-dom"
import { useTicketContext } from "../../context/TicketContext"
import { languageSelector as ls } from "../../languages/languageSelector"
import Option from "../ui/option/Option"
import OptionRow from "../ui/option row/OptionRow"

const Subissues = () => {
    const { issue, product, children, setSubissue } = useTicketContext()
    const navigate = useNavigate()

    return (
        <Option type={'subissues'} product={product} onClick={()=>navigate('/')} >
            <ul className={'max-w-3xl h-full flex justify-center flex-col text-left'}>
                <h1 className={'text-white text-xl text-center font-bold border-b-2 border-copper py-3'}>{ls.getText("when_happen")}</h1>
                {children.map((child, i)=>{
                    let row
                    if(typeof child !== 'string'){
                        row = <OptionRow key={i} name={child.name} pointer={true} onClick={()=> {
                            setSubissue({id: child.id, name: child.name})
                            if (child.common_solutions){
                                navigate({
                                    pathname: "/common_solutions",
                                    search: createSearchParams({
                                        p: product.id,
                                        i: issue.id,
                                        s: child.id
                                    }).toString()
                                })
                            }else{
                                navigate({
                                    pathname: "/ticket",
                                    search: createSearchParams({
                                        p: product.id,
                                        i: issue.id,
                                        s: child.id
                                    }).toString()
                                })
                            }
                            
                        }} />
                    }
                    return(
                        <div key={i} className={'border-b-2 border-slate py-3'}>
                            {row}
                        </div>)           
                    })
                }
                <div key={'other'} className={'py-3'}>
                    <OptionRow 
                        key={'otherInner'} 
                        name={ls.getText('other')} 
                        pointer={true} 
                        onClick={()=> {navigate({
                            pathname: "/ticket",
                            search: createSearchParams({
                                p: product.id
                            }).toString()
                        })} 
                    }/>
                </div>
            </ul>
        </Option>
    )
}

export default Subissues;
