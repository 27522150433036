import { useNavigate } from 'react-router-dom';
import { languageSelector as ls } from '../../../languages/languageSelector';
import logo from './../../../assets/images/logo.png';

const Header = () => {
    const navigate = useNavigate()

    return (
        <div className="flex justify-between bg-black sm:px-10 py-1 px-5">
            <div className={'flex-1'}>
                <img
                    className="object-contain my-2 h-14 sm:h-20 cursor-pointer"
                    src={logo}
                    alt="Covvi Logo"
                    onClick={()=> navigate('/')}
                    />
            </div>
            <div className="grid grid-cols-1 content-center pl-5 ">
                <div className={'text-white font-bold my-auto text-base sm:text-2xl flex-1 text-right '}>
                    {ls.getText('support_dash')}
                </div>
                <div className={'text-copper font-bold my-auto text-base sm:text-lg flex-1 text-right cursor-pointer'} onClick={() => {window.location.href = 'https://covvi.com'}}>
                    {ls.getText('return_covvi')}
                </div>
            </div>
        </div>
    )
} 

export default Header;