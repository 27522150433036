interface LanguageSelector {
    language: string
    texts: {
        [key: string]: string 
    }
    enTexts: {
        [key: string]: string 
    }
}


class LanguageSelector {
  constructor() {
    // Check if a language is saved locally
    // AsyncStorage.getItem("language")
    //   .then((res) => {
    //     console.log(res)
    //     this.setLanguage("en")
    //   })
  }

  setLanguage(language = "en") {
    return new Promise((resolve, reject) => {
      if (this.language === language) {
        resolve(this.texts)
      }
      localStorage.setItem("language", language)
        this.language = language
        switch (language) {
            case "zh":
              this.texts = require("../languages/zh/zh.json")
              break
            default:
              this.texts = require("../languages/en/en.json")
          }
          this.enTexts = require("../languages/en/en.json")
    })
  }

  getText(ref:string) {
    const text = this.texts[ref]
    return text ? (text) : this.enTexts[ref]//ref
  }
}

const languageSelector = new LanguageSelector();

export { languageSelector }