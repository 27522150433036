type AppProps = {
    name: string
    onClick: () => void
    transparent?: boolean
    width?: string
    disabled?: boolean
    }

const Button = (props: AppProps): JSX.Element => {
    let style
    if (props.transparent){
        style = "bg-transparent text-copper hover:text-copperHover"
    }else if (props.disabled){
        style = "bg-copperHover hover:bg-copperHover text-white cursor-not-allowed"
    }else{
        style = "bg-copper hover:bg-copperHover text-white"
    }
    const width = props.width ? props.width : 'w-1/3'

    const clickHandler = () => {
        if (!props.disabled) props.onClick()
    }

    return (
        <div className={`w-full flex justify-center`}>
            <button className={`font-bold py-2 px-4 mx-2 focus:outline-none ${style} ${width}`} onClick={clickHandler}>
                <div className=" flex flex-row text-center items-center justify-center">
                    {props.transparent && 
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                    }
                    <p className="mb-0">{props.name}</p>
                </div>            
            </button>
        </div>
    )
}

export default Button;