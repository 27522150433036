import { Dialog, Transition } from '@headlessui/react'
import { Fragment,  } from 'react'
import Button from '../button/Button';

type ModalProps = {
  showModal: boolean
  title: string
  content: any
  buttonClose?:{
    title: string
    onClick: ()=> void
  }
  buttonOther?:{
    title: string
    onClick: ()=>void
  }
}

const Modal = (props:ModalProps) => {
  const ClickHandlerClose = () => {
    if(props.buttonClose){
      props.buttonClose.onClick()
    }else return
  }

  const ClickHandlerOther = () => {
    if(props.buttonOther){
      props.buttonOther.onClick()
    }else return
  }
  
    return (
        <>
          <Transition appear show={props.showModal} as={Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 z-10 overflow-y-auto"
              onClose={()=>ClickHandlerClose()}
            >
              <div className="min-h-screen px-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
                </Transition.Child>
    
                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="inline-block h-screen align-middle"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-main shadow-xl ">
                    <Dialog.Title as='h3' className="text-xl leading-6 text-white font-extrabold bg-main text-center">
                      {props.title}
                    </Dialog.Title>

                    {props.content}
                    
                    <div className={`mt-4 flex ${ props.buttonOther ? 'justify-between': 'justify-center'}`}>
                      {props.buttonClose && <Button name={props.buttonClose.title} onClick={()=>ClickHandlerClose()} />}
                      {props.buttonOther && <Button name={props.buttonOther.title} onClick={()=>ClickHandlerOther()} />}
                    </div>

                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>
        </>
      )
}

export default Modal;