import { useNavigate, createSearchParams } from "react-router-dom"
import { useTicketContext } from "../../context/TicketContext"
import { languageSelector as ls } from "../../languages/languageSelector"
import Catagory from "../ui/catagory/Catagory"
import Option from "../ui/option/Option"


const Issues = () => {
    const { product, children, setIssue } = useTicketContext()
    const navigate = useNavigate()
    
    return (
        <Option type={'issues'} product={product} onClick={()=>navigate('/')}>
            <div className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-4 text-white">
                {children.map((child, i)=>{
                    let catagory
                    if(typeof child !== 'string'){
                        catagory = <Catagory key={i} name={child.name} id={child.id}onClick={()=> {
                            setIssue({id: child.id, name: child.name})
                            if (child.options){
                                navigate({
                                    pathname: "/subissues",
                                    search: createSearchParams({
                                        p: product.id,
                                        i: child.id
                                    }).toString()
                                })
                            }else if (child.common_solutions){
                                navigate({
                                    pathname: "/common_solutions",
                                    search: createSearchParams({
                                        p: product.id,
                                        i: child.id
                                    }).toString()
                                })
                            }else{
                                navigate({
                                    pathname: "/ticket",
                                    search: createSearchParams({
                                        p: product.id,
                                        i: child.id
                                    }).toString()
                                })
                            }
                        }}/>
                    }
                    return catagory
                })}
                <Catagory 
                    key={'other'} 
                    name={ls.getText('other')} 
                    id={'other_catagory'} 
                    onClick={()=> {navigate({
                        pathname: "/ticket",
                        search: createSearchParams({
                            p: product.id
                        }).toString()
                    })}} />
            </div>
        </Option>
    )
}

export default Issues;
