import { useState, useEffect } from 'react';
import { useSearchParams, Outlet, useLocation } from "react-router-dom";
import { TicketContext } from './context/TicketContext';
import { TicketInterface, TicketObject } from './types/TicketTypes';
import { languageSelector as ls } from './languages/languageSelector';
import Layout from './components/ui/layout/layout';
import structure from './util/structure.json';

const App = () => {
  const [product, setProduct] = useState<TicketObject>({id:'',name:''})
  const [issue, setIssue] = useState<TicketObject>({id:'',name:''})
  const [subissue, setSubissue] = useState<TicketObject>({id:'',name:''})
  const [formOptions, setFormOptions] = useState<string[]>([])
  const [type, setType] = useState<string>('')
  const [children, setChildren] = useState<TicketInterface[] | string[]>([])
  const [searchParams] = useSearchParams()

  ls.setLanguage("en")
    .then(() => {
    })
    .catch((error) => {
      console.error(error)
    })

  let typeParam = useLocation().pathname.slice(1)
  let productParam = searchParams.get('p')
  let issueParam = searchParams.get('i')
  let subissueParam = searchParams.get('s')

  useEffect(()=>{setType(typeParam)},[typeParam])

  useEffect(()=>{

    if (type !== ''){
      structure.forEach((product)=>{
        if (product.id === productParam  && product.options){
          setProduct({id:product.id, name:product.name})
          setFormOptions(product.form_options)
          const issues = product.options

          if(type === 'issues') {
            setChildren(issues)
          }else{
            issues.forEach((issue)=>{
              if(issue.id === issueParam){
                setIssue({id:issue.id, name:issue.name})
                if(issue.options){
                  const subissues = issue.options

                  if(type === 'subissues') {
                    setChildren(subissues)
                  }else{
                    subissues.forEach(subissue=>{
                      if (subissue.id === subissueParam && subissue.common_solutions){
                        setSubissue({id:subissue.id, name:subissue.name})
                        if (subissue.form_options) {
                          setFormOptions(subissue.form_options)
                        }

                        if(type === 'common_solutions'){
                          setChildren(subissue.common_solutions)
                        }
                      }
                    })

                  }
  
                }
              }
            })

          }

        }
      })
    }
  },[type, productParam, issueParam, subissueParam])

  return (
    <div className="bg-backGround text-center">
      <TicketContext.Provider value={{
        product, setProduct, 
        issue, setIssue, 
        subissue, setSubissue, 
        formOptions, setFormOptions,
        type, setType,
        children, setChildren
      }}>

        <Layout>
          <Outlet />
        </Layout>

      </TicketContext.Provider>
    </div>
  );
}

export default App;

