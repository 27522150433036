type OptionProps = {
    id: string
    name: string
    onClick?: () => void
    width?: string
}
  
const Product = (props:OptionProps) => {

    let imgSrc
    try{
        require(`./../../../assets/images/options/${props.id}.png`)
        imgSrc = props.id
    } catch (err) {
        imgSrc = 'other_product'
    }
    
    return(
    <div className={`${props.width? props.width : 'w-1/2 md:w-1/3'} max-w-md`}>
        <div className={`group bg-black rounded-2xl overflow-hidden ${props.onClick && 'cursor-pointer'} m-3`} onClick={props.onClick}>
            <div className="bg-slateDark p-3">
                <img 
                    className={`${props.onClick && 'group transition ease-in-out delay-150 group-hover:scale-105 duration-300'}`} 
                    alt={props.name} 
                    src={require(`./../../../assets/images/options/${imgSrc}.png`)} 
                />
            </div>
            <h2 className={'text-white font-extrabold text-md sm:text-2xl py-2 sm:py-3 border-t-2 border-copper'}>
                {props.name}
            </h2>
        </div>
    </div>
    )
}

export default Product;