import Label from "../Label"

type SelectionProps = {
    state:{
        name: string
        labelText: string
        options: string[]
        value:string
    }
    onChange: (e: string) => void
}

const Selection = (props:SelectionProps) => {
    return (
        <div className="w-full md:w-1/3 sm:px-3 mb-7 sm:mb-3">
            <Label labelFor={'grid-selection'} labelText={props.state.labelText} />
            <div className="relative">
                <select className="block appearance-none w-full bg-backGround text-white py-3 px-4 pr-8 border-2 leading-tight focus:outline-none focus:ring-0 focus:border-2 focus:border-copper focus:bg-backGround focus:text-white" 
                    id={`select-${props.state.name}`}
                    value={props.state.value}
                    onChange={(e)=>{
                        const {value} = e.target
                        props.onChange(value)
                    }}>
                    {props.state.options.map((option, i) => {
                        return <option key={i}>{option}</option>
                    })}
                </select>
            </div>
        </div>
    )
}

export default Selection;